import React from "react";
import LogoutDropdown from "./Logout";
// import CustomTooltip from "../Common/CustomTooltip";

const Header = ({
  title,
  tooltipContent,
  tooltipPlacement,
  infoTooltip,
  tooltipContentTwo,
  tooltipContentThree,
  tooltipContentFour,
  toggleTheme,
  darkMode,
}) => {
  return (
    <div className="header">
      <div className="title">{title}</div>
      <div className="welcome-user">
        <LogoutDropdown toggleTheme={toggleTheme} darkMode={darkMode} />
      </div>
    </div>
  );
};

export default Header;
