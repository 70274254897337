import React, { useState, useEffect, useRef } from "react";
import styled, { ThemeProvider, createGlobalStyle } from "styled-components";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "./styles.css";
import { formatDate } from "../../utils";
import { getUsersList } from "../../api";
import Header from "../Header/Header";
import ConfirmModal from "./ConfirmModal";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${(props) => props.theme.body};
    color: ${(props) => props.theme.text};
    transition: all 0.3s linear;
  }
`;

const lightTheme = {
  body: "#EAEFF1",
  text: "#1C1C1C",
  cardBackground: "#fff",
  cardBorder: "rgba(255, 255, 255, 0.18)",
};

const darkTheme = {
  body: "#121212",
  text: "#EAEFF1",
  cardBackground: "#1c1c1c",
  cardBorder: "rgba(255, 255, 255, 0.18)",
};

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 20px;
  margin-bottom: 16px;
  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Card = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 85px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 8px;
  backdrop-filter: blur(10px);
  background: ${(props) => props.theme.cardBackground};
  border: 1px solid ${(props) => props.theme.cardBorder};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  font-size: 18px;
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    box-shadow: 5px 5px 15px #77777778;
    transform: translateY(-1px);
  }
  &:active {
    box-shadow: 5px 5px 15px #77777778;

    transform: translateY(1px);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const GridContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  position: relative;

  .ag-theme-quartz {
    min-width: 700px;
  }

  @media (max-width: 768px) {
    .ag-theme-quartz {
      min-width: max-content;
    }
  }
  .ag-header-cell.no-hamburger .ag-header-cell-menu-button {
    display: none !important;
    font-weight: bold !important;
  }
  .ag-header-cell-text {
    font-weight: 600 !important;
  }
  .ag-table-refresh {
    margin-bottom: 10px;
    position: absolute;
    z-index: 99;
    top: 54px;
    right: 20px;
  }
`;

const getRowClass = (params) => {
  if (params.node.rowIndex !== undefined) {
    if (params.data.access === "pending") {
      return "row-pending";
    } else if (params.data.access === "approved") {
      return "row-approved";
    } else if (params.data.access === "rejected") {
      return "row-rejected";
    }
  }
  return "";
};

const Home = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [userList, setUserList] = useState();
  const [rowData, setRowData] = useState([]);
  const [count, setCount] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [modalType, setModalType] = useState("");
  const [collapsed, setCollapsed] = useState(false);
  const gridRef = useRef();

  const getUsers = async () => {
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.showLoadingOverlay();
    }
    try {
      const onLoad = await getUsersList();
      const res = onLoad.data.result;
      setUserList(res);
      const sortedData = res.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      );
      const dataForTable = sortedData.map((user) => ({
        id: user.id,
        name: user.firstName.concat(" ", user.lastName),
        email: user.email,
        organization: user.organization,
        phone: user.phoneNumber,
        access: user.access,
        timestamp: formatDate(user.updatedAt),
      }));
      setRowData(dataForTable);
      setFilteredData(dataForTable);
      updateCounts(res);
      if (gridRef.current && gridRef.current.api) {
        gridRef.current.api.hideOverlay(); // Hide the loading overlay
      }
    } catch (error) {
      alert(
        "Hmm. Something doesn't seem right. Try refreshing or logging in again."
      );
    }
    if (gridRef.current && gridRef.current.api) {
      gridRef.current.api.hideOverlay(); // Hide loading overlay
    }
  };

  const updateCounts = (userList) => {
    const data = {
      pending: 0,
      approved: 0,
      rejected: 0,
    };
    userList.map((x) => {
      if (x.access == "pending") data.pending += 1;
      else if (x.access == "approved") data.approved += 1;
      else if (x.access == "rejected") data.rejected += 1;
      else data.closed += 1;
    });
    setCount(Object.assign({}, data));
  };

  useEffect(() => {
    getUsers();
  }, []);

  const toggleTheme = () => {
    setDarkMode(!darkMode);
  };

  const openAcessModal = (data, type) => {
    setModalData(data);
    setModalType(type);
    setConfirmModalShow(true);
  };

  const AccessCellRenderer = (props) => (
    <>
      {props.data.access == "pending" ? (
        <span
          className={`${darkMode ? "pending-badge-outline" : "pending-badge"}`}
        >
          Pending
        </span>
      ) : props.data.access == "approved" ? (
        <span
          className={`${darkMode ? "success-badge-outline" : "success-badge"}`}
        >
          Approved
        </span>
      ) : (
        <span
          className={`${
            darkMode ? "danger-badge-outline dsm-btn-sm" : "danger-badge"
          }`}
        >
          Rejected
        </span>
      )}
    </>
  );

  const ActionCellRenderer = (props) => (
    <>
      {(props.data.access == "pending" || props.data.access == "rejected") && (
        <>
          <span
            className={`${
              darkMode
                ? "dsm-btn-primary-outline dsm-btn-sm"
                : "dsm-btn-secondary dsm-btn-sm"
            }`}
            onClick={() => openAcessModal(props.data, "enable")}
          >
            <i className={`bi bi-check-lg me-1 ${darkMode ? "" : ""}`}></i>
            Enable
          </span>
        </>
      )}
      {props.data.access == "approved" && (
        <span
          className={`${
            darkMode
              ? "dsm-btn-danger-outline dsm-btn-sm"
              : "dsm-btn-danger dsm-btn-sm"
          }`}
          onClick={() => openAcessModal(props.data, "disable")}
        >
          <i className={`bi bi-x me-1 ${darkMode ? "" : ""}`}></i>Disable
        </span>
      )}
    </>
  );

  const columnDefs = [
    {
      headerName: "ID",
      field: "id",
      floatingFilter: false,
      filter: false,
      sortable: true,
      flex: 0.5,
      headerClass: "no-hamburger",
    },
    {
      headerName: "Name",
      field: "name",
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true,
      flex: 0.8,
      headerClass: "no-hamburger",
    },

    {
      headerName: "Email",
      field: "email",
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true,
      flex: 1,
      headerClass: "no-hamburger",
    },

    {
      headerName: "Organization",
      field: "organization",
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true,
      flex: 0.7,
      headerClass: "no-hamburger",
    },
    {
      headerName: "Status",
      field: "access",
      cellRenderer: AccessCellRenderer,
      floatingFilter: true,
      filter: "agTextColumnFilter",
      sortable: true,
      flex: 0.7,
      headerClass: "no-hamburger",
    },
    {
      headerName: "Last Updated",
      field: "timestamp",
      floatingFilter: false,
      filter: false,
      sortable: true,
      flex: 0.9,
      headerClass: "no-hamburger",
    },
    {
      headerName: "Action",
      field: "action",
      cellRenderer: ActionCellRenderer,
      sortable: false,
      filter: false,
      flex: 0.6,
      headerClass: "no-hamburger",
    },
  ];

  const filterTotalData = () => {
    setFilteredData(rowData);
  };

  const filterPendingData = () => {
    setFilteredData(rowData.filter((item) => item.access === "pending"));
  };

  const filterApprovedData = () => {
    setFilteredData(rowData.filter((item) => item.access === "approved"));
  };

  const filterRejectedData = () => {
    setFilteredData(rowData.filter((item) => item.access === "rejected"));
  };

  const resetFilters = () => {
    getUsers();
  };

  return (
    <div
      className={`right-container ${
        collapsed ? "collapsed-right-container" : ""
      }`}
    >
      <div className="main-header"></div>
      <div className="inner-body-container">
        <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
          <GlobalStyle />

          <div className="main-header">
            <Header
              title="Requests"
              toggleTheme={toggleTheme}
              darkMode={darkMode}
            />
          </div>

          {/* <SwitchButton onClick={toggleTheme}>
              <i
                className={`bi ${
                  darkMode
                    ? " bi-sun-fill text-orange"
                    : " bi-cloud-moon-fill text-blue"
                }`}
              ></i>
            </SwitchButton> */}
          <CardContainer>
            <Card onClick={filterTotalData}>
              {" "}
              <i
                className={`icon bi bi-list text-blue ${darkMode ? "" : ""}`}
              ></i>
              <div>
                <h6 className="text-blue fw-bolder text-end">
                  {userList ? userList?.length : 0}
                </h6>
                Total
              </div>
            </Card>
            <Card onClick={filterPendingData}>
              {" "}
              <i
                className={`icon bi bi-clock-history text-orange ${
                  darkMode ? "" : ""
                }`}
              ></i>
              <div>
                <h6 className="text-orange fw-bolder text-end">
                  {count ? count?.pending : 0}
                </h6>
                Pending
              </div>
            </Card>
            <Card onClick={filterApprovedData}>
              {" "}
              <i
                className={`icon bi bi-list-check text-green ${
                  darkMode ? "" : ""
                }`}
              ></i>
              <div>
                <h6 className="text-green fw-bolder text-end">
                  {count ? count?.approved : 0}
                </h6>
                Approved
              </div>
            </Card>
            <Card onClick={filterRejectedData}>
              <i
                className={` icon bi bi-slash-circle text-red ${
                  darkMode ? "" : ""
                }`}
              ></i>
              <div>
                <h6 className="text-red fw-bolder text-end">
                  {count?.rejected ? count?.rejected : 0}
                </h6>
                Rejected
              </div>
            </Card>
          </CardContainer>
          <GridContainer>
            <button
              onClick={getUsers}
              style={{ marginBottom: "10px" }}
              className="dsm-btn-secondary ag-table-refresh"
            >
              <i class="bi bi-arrow-counterclockwise fw-bolder "></i>
            </button>
            <div
              className={`ag-theme-quartz${darkMode ? "-dark" : ""}`}
              style={{ height: 550 }}
            >
              <AgGridReact
                ref={gridRef}
                columnDefs={columnDefs}
                rowData={filteredData}
                defaultColDef={{
                  sortable: true,
                  filter: true,
                  floatingFilter: true,
                }}
                suppressRowClickSelection={true}
                pagination={true}
                paginationPageSize={10}
                paginationPageSizeSelector={[10, 25, 50]}
              />
            </div>
          </GridContainer>
        </ThemeProvider>
      </div>
      {confirmModalShow && (
        <ConfirmModal
          show={confirmModalShow}
          onHide={() => setConfirmModalShow(false)}
          data={modalData}
          type={modalType}
          setOpenModal={setConfirmModalShow}
          refetchData={getUsers}
          darkMode={darkMode}
        />
      )}
    </div>
  );
};

export default Home;
