import Keycloak from "keycloak-js";

const _kc = new Keycloak("/keycloak.json");

let keycloakInitialized = false;

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  if (!keycloakInitialized) {
    _kc
      .init({
        onLoad: "check-sso",
        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
        pkceMethod: "S256",
        redirectUri: window.location.origin + '/home',
      })
      .then((authenticated) => {
        if (authenticated) {
          setToken(_kc.token);
          onAuthenticatedCallback();
          startTokenRefresh();
        } else {
          console.warn("User is not authenticated.");
          onAuthenticatedCallback();
        }
      })
      .catch((error) => {
        console.error("Keycloak initialization error:", error);
      });
    keycloakInitialized = true;
  } else {
    onAuthenticatedCallback();
  }
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const getTokenParsed = () => _kc.tokenParsed;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
  _kc.updateToken(5)
    .then(() => {
      setToken(_kc.token);
      successCallback();
    })
    .catch(doLogin);

const getUsername = () => _kc.tokenParsed;
const getUsernames = () => _kc.tokenParsed?.given_name;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));

const setToken = (newToken) => {
  localStorage.setItem('authToken', newToken);
};

// Function to start the token refresh interval
const startTokenRefresh = () => {
  setInterval(() => {
    updateToken(() => {
      console.log("Token successfully refreshed");
    });
  }, 2.5 * 60 * 1000); // 2 minutes 30 seconds
};

const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  getTokenParsed,
  updateToken,
  getUsername,
  getUsernames,
  hasRole,
};

export default UserService;
