import { approveRequest, rejectRequest } from "../../api";
import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { Spinner } from "react-bootstrap";
import WarningIcon from "../../assets/images/warning-circle.svg";
import { toast } from "react-toastify";
import { toastSuccess } from "../Common/ToastAlert";

const ConfirmModal = ({
  show,
  data,
  refetchData,
  setOpenModal,
  onHide,
  darkMode,
  type,
}) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [style, setStyle] = useState("");

  const handleApproveClick = async (id) => {
    setConfirmLoading(true);
    try {
      const onApprove = await approveRequest({ adminUserId: id });
      setConfirmLoading(false);
      onHide();

      refetchData();
    } catch (error) {
      setConfirmLoading(false);

      console.log(error);
    }
  };

  const handleRejectClick = async (id) => {
    try {
      const onApprove = await rejectRequest({ adminUserId: id });
      refetchData();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    console.log(darkMode, type, "darkmode", data);
  }, []);

  const handleConfirm = async () => {
    setConfirmLoading(true);
    try {
      if (type === "enable") {
        await approveRequest({ adminUserId: data.id });
        toast.success(`${data?.name} request is approved and has access to NCC with ${data?.email}`, toastSuccess());
      } else {
        await rejectRequest({ adminUserId: data.id });
        toast.success(`${data?.name} request is rejected and has NO access to NCC with ${data?.email}`, toastSuccess());


      }
      refetchData();
      setConfirmLoading(false);
      setConfirmLoading(false);
      setOpenModal(false);
    } catch (error) {
      console.log(error);
    }
  };
  const btn = (type, darkMode) => {
    if (type === "enable" && darkMode)
      return setStyle("dsm-btn-primary-outline");
    else if (type === "enable" && !darkMode) return setStyle("dsm-btn-primary");
    else if (type === "disable" && !darkMode) return setStyle("dsm-btn-danger");
    else if (type === "disable" && darkMode)
      return setStyle("dsm-btn-danger-outline");
  };

  useEffect(() => {
    btn(type, darkMode);
  }, []);

  return (
    <Modal
      show={show}
      onHide={onHide}
      size="md"
      centered
      contentClassName={` ${
        darkMode ? "bg-darkMode text-light" : "bg-light text-dark"
      }`}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <Modal.Title>
            {" "}
            <img src={WarningIcon} alt="warning" />{" "}
            {type === "enable" ? "Enable Access" : "Disable Access"}
          </Modal.Title>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to {type} access to Network Control Center for{" "}
        {data?.name} ({data?.email})?
      </Modal.Body>
      <Modal.Footer>
        <button
          type="button"
          className={darkMode ? "dsm-btn-light" : "dsm-btn-secondary"}
          onClick={onHide}
        >
          Do it Later
        </button>

        <button
          type="button"
          className={style}
          onClick={handleConfirm}
          disabled={confirmLoading}
        >
          {confirmLoading ? (
            <Spinner
              animation="border"
              size="sm"
              variant={darkMode ? "light" : "dark"}
            />
          ) : type === "enable" ? (
            "Enable"
          ) : (
            "Disable"
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModal;
