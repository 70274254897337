import { Navigate, createBrowserRouter } from "react-router-dom";

import Notfound from "../components/NotFound";


// tg-admin
import Root from "../components/Root";
import Home from "../components/Home";

const Pages = createBrowserRouter([
  
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true,
        element: <Navigate to="/home" replace />,
      },
      {
        path: "/home",
        element: <Home />,
      },
     
    
    ],
  },
  

  {
    path: "*",
    element: <Notfound />,
  },
]);
export default Pages;
