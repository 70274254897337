import Pages from "./pages";
import { RouterProvider } from "react-router-dom";
import RenderOnAuthenticated from "./keycloak/RenderOnAuthenticated";
import RenderOnAnonymous from "./keycloak/RenderOnAnonymous";
import Welcome from "./pages/Welcome";
import { GlobalStyle, darkTheme, lightTheme } from "./theme";
import { ThemeProvider } from "styled-components";

function App() {
  return (
    <>
      <RenderOnAnonymous>
        <Welcome />
      </RenderOnAnonymous>
      <RenderOnAuthenticated>
      <ThemeProvider theme={darkTheme ? darkTheme : lightTheme}>
          <GlobalStyle />
        <RouterProvider router={Pages} />
        </ThemeProvider>
      </RenderOnAuthenticated>
    </>
  );
}

export default App;
