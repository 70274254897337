import { Col, Row } from "react-bootstrap";
import UserService from "../keycloak/UserService";
import { useAuth } from "../context/AuthContext";
import TrustGridLogo from "../assets/images/logo.svg";
import LandingImage from "../assets/images/landing-img.svg";

import "../assets/styles/_welcome.scss";

const Welcome = () => {
  const { handleLogin } = useAuth();
  return (
    <div className="landing-page-main vh-100">
      <div className="body">
        <div className="brand-logo">
          <img
            src={TrustGridLogo}
            alt="logo"
            className="img-fluid"
            width={240}
            height={50}
          />
        </div>
        <Row className="mb-5">
          <Col
            xl={7}
            lg={7}
            md={12}
            sm={12}
            className="order-lg-1 order-md-2 order-sm-2 order-1"
          >
            <div className="welcome-content">
              <h4>
                Welcome to TrustGrid<sup className="sup">TM</sup> Guardian
              </h4>
              <p className="mb-4 text-justify">
                Welcome to the TrustGrid Guardian platform, your comprehensive
                solution for managing access to the Network Control Center. With
                TrustGrid Guardian, you can seamlessly grant and revoke access
                for NCC portal users, ensuring secure and efficient user
                management.
              </p>
              <button className="dsm-btn-primary w-50" onClick={handleLogin}>
                Login
              </button>
            </div>
          </Col>
          <Col
            xl={5}
            lg={5}
            md={12}
            sm={12}
            className="order-lg-2 order-md-1 order-sm-1 order-2"
          >
            <div className="decentral-logo">
              <img src={LandingImage} alt="logo" />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Welcome;
