import React, { useEffect, useState } from "react";
import { DropdownItem, DropdownMenu } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";

import LogoutIcon from "../../assets/images/logout.svg";
import UserIcon from "../../assets/images/logout-user.svg";
import UserIconDark from "../../assets/images/UserCircle.svg"

import UserService from "../../keycloak/UserService";
import LogoutPopup from "./LogoutPopup";
import { SwitchButton, darkTheme, lightTheme } from "../theme";
import { ThemeProvider } from "styled-components";
const LogoutDropdown = ({ toggleTheme, darkMode }) => {
  const [user, setUser] = useState("");
  const [openModal, setOpenModal] = useState(false);
  useEffect(() => {
    const storedUsername = localStorage.getItem("username");
    if (storedUsername) {
      setUser(storedUsername);
    } else {
      const fetchUsername = async () => {
        const username = UserService.getUsername()?.given_name;
        console.log(username, "username");
        if (username) {
          setUser(username);
          localStorage.setItem("username", username);
        }
      };

      fetchUsername();
    }
  }, []);
  return (
    <React.Fragment>
      <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        {openModal && (
          <LogoutPopup
            show={openModal}
            darkMode={darkMode}
            handleClose={() => setOpenModal(false)}
          />
        )}
        <div className="logout-main welcome-user">
          <p className={`user-name ${darkMode ? "text-light" : "text-dark"}`}>
            {user ? ` ${user}` : "Hello User"}
          </p>
          <Dropdown>
            <Dropdown.Toggle
              variant="success"
              className="dropdown-toggle"
              type="button"
              id="dropdownMenuButton"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img src={darkMode ? UserIconDark : UserIcon} alt="user-icon" />
            </Dropdown.Toggle>
            <DropdownMenu
         
              className={`dropdown-menu ${darkMode ? "bg-black" : "bg-light"}`}
              aria-labelledby="dropdownMenuButton"
            >
              <DropdownItem>
                <button
                  className="dropdown-item"
                  onClick={() => setOpenModal(true)}
                >
                  <img src={LogoutIcon} alt="logout" />
                  <span className="logout-text">Logout</span>
                </button>
              </DropdownItem>

              <DropdownItem>
                <button className="dropdown-item" onClick={toggleTheme}>
                  <i
                    className={`bi pe-1 ${
                      darkMode
                        ? " bi-sun-fill text-orange"
                        : " bi-cloud-moon-fill text-blue"
                    }`}
                  ></i>

                  <span className={` ${darkMode ? "text-orange" : "text-blue"}`}>
                    {darkMode ? "Day Mode" : "Night Mode"}
                  </span>
                </button>
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </ThemeProvider>
    </React.Fragment>
  );
};

export default LogoutDropdown;
