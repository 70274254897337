import axios from "axios";
const BASE_URL=process.env.REACT_APP_BASE_URL;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = await getToken();
    if (token) {
      config.headers.Authorization = `${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

async function getToken() {
  return localStorage.getItem('authToken');
}

export async function getUsersList() {
  return await axiosInstance.get(`${BASE_URL}/approval/list/`);
}

export async function approveRequest(data) {
    return await axiosInstance.post(`${BASE_URL}/approval/approve`, data);
  }

  export async function rejectRequest(data) {
    return await axiosInstance.post(`${BASE_URL}/approval/reject`, data);
  }
