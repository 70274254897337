import { createGlobalStyle, styled } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${(props) => props.theme.body};
    color: ${(props) => props.theme.text};
    transition: all 0.3s linear;
  }
`;

export const lightTheme = {
  body: "#EAEFF1",
  text: "#1C1C1C",
  cardBackground: "#fff",
  cardBorder: "rgba(255, 255, 255, 0.18)",
};

export const darkTheme = {
  body: "#121212",
  text: "#EAEFF1",
  cardBackground: "#1c1c1c",
  cardBorder: "rgba(255, 255, 255, 0.18)",
};

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 20px;
  margin-bottom: 16px;
  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 992px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Card = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 85px;
  padding-left: 25px;
  padding-right: 25px;
  border-radius: 8px;
  backdrop-filter: blur(10px);
  background: ${(props) => props.theme.cardBackground};
  border: 1px solid ${(props) => props.theme.cardBorder};
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  font-size: 18px;
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    box-shadow: 5px 5px 15px #77777778;
    transform: translateY(-1px);
  }
  &:active {
    box-shadow: 5px 5px 15px #77777778;

    transform: translateY(1px);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

export const GridContainer = styled.div`
  width: 100%;
  overflow-x: auto;

  .ag-theme-quartz {
    min-width: 700px;
  }

  @media (max-width: 768px) {
    .ag-theme-quartz {
      min-width: max-content;
    }
  }
`;