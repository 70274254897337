import React, { useEffect, useRef, useState } from "react";
import { Link, NavLink, Outlet } from "react-router-dom";

// import
import TrustGridLogo from "../../assets/images/logo.svg";
import GreterThen from "../../assets/images/greterthen.svg";
import LessThen from "../../assets/images/lessthen.svg";
import CollapseLogo from "../../assets/images/collapse-trustgrid.svg";
import "../../assets/styles/app.scss";

const Root = () => {
  const [collapsed, setCollapsed] = useState(false);
  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        window.innerWidth <= 768 &&
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target)
      ) {
        setCollapsed(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarRef]);

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div className="main-layout container-fluid  p-0">
      <nav
        ref={sidebarRef}
          className={`sidebar-menu ${collapsed ? "collapsed-menu" : ""}`}
      >
        <button onClick={toggleSidebar} className="toggle-button">
          {collapsed ? (
            <img src={GreterThen} alt="logo" />
          ) : (
            <img src={LessThen} alt="logo" />
          )}
        </button>
        <div className="d-flex flex-column flex-shrink-0">
          <Link to="/">
            {collapsed ? (
              <div className="collapse-brand-logo">
                <img src={CollapseLogo} alt="logo" width={48} height={48} />
              </div>
            ) : (
              <div className="brand-logo">
                <img src={TrustGridLogo} alt="logo" />
              </div>
            )}
          </Link>
          <ul className="nav nav-pills flex-column mb-auto nav-menu">
            <li className="nav-item">
              <NavLink to="/home" className="dashboard">
                {!collapsed ? (
                  <span className="dashboard-wiz">Requests</span>
                ) : (
                  ""
                )}
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
      <Outlet />
    </div>
  );
};

export default Root;
