export const getTimeDiff = (timestamp) => {
    const now = new Date().getTime();
    const diff = now - timestamp;

    const seconds = Math.floor((diff / 1000) % 60);
    const minutes = Math.floor((diff / (1000 * 60)) % 60);
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);

    return `${hours} hours, ${minutes} minutes, ${seconds} seconds ago`;
  };

  export const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.toLocaleString("en-US", { weekday: "short" });
    const dayOfMonth = String(date.getDate()).padStart(2, "0");
    const month = date.toLocaleString("en-US", { month: "short" }); // "June"
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const formattedDate = `${dayOfMonth} ${month} ${year}, ${hours}:${minutes}:${seconds}`;
  return formattedDate
    // return (
    //   <>
    //     {}
    //     <p style={{ fontSize: "12px"}}>
    //     {formattedDate}{" "}{formattedTime}
    //     </p>
    //   </>
    // );
  };

  export const isMobileDevice = () => {
    return /Mobi|Android/i.test(navigator.userAgent);
  };