import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${(props) => props.theme.body};
    color: ${(props) => props.theme.text};
    transition: all 0.3s linear;
  }
`;

export const lightTheme = {
  body: "#EAEFF1",
  background: "#EAEFF1",
  text: "#1C1C1C",
  cardBackground: "#fff",
  cardBorder: "rgba(255, 255, 255, 0.18)",
};

export const darkTheme = {
  body: "#121212",
  background:"#121212",
  text: "#EAEFF1",
  cardBackground: "#1c1c1c",
  cardBorder: "rgba(255, 255, 255, 0.18)",
};

export const SwitchButton = styled.div`
  background: ${(props) => props.theme.body};
  color: ${(props) => props.theme.text};
  border-radius: 50%;
  padding: 10px 20px;
  cursor: pointer;
  margin-bottom: 20px;
  font-weight: 900;
  font-size: 25px;
`;